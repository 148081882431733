export default [
  {
    id: 0,
    path: '/',
    label: 'Home'
  },
  {
    id: 1,
    path: '/support',
    label: 'Airly Chair'
  },
  {
    id: 2,
    path: '/about',
    label: '企業情報'
  },
  
  // {
  //   id: 3,
  //   path: '/news',
  //   label: 'News'
  // },
]
