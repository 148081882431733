import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b3b83b5"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
import { useStore } from 'vuex';
import { computed, reactive } from '@vue/runtime-core';
import { VideoPlay } from '@element-plus/icons-vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
export default {
  __name: 'VideosSwiper',
  props: {
    videos: Object
  },
  setup(__props) {
    const store = useStore();
    const modules = [Pagination, Navigation, Autoplay];
    let isMobile = computed(() => store.state.bk.global.isMobile);
    const data = reactive({
      video: {},
      modalShow: false
    });
    const openVideo = obj => {
      data.modalShow = true;
      data.video = obj;
    };
    const close = () => {
      data.modalShow = false;
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_PopCover = _resolveComponent("PopCover");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['videos-swiper w100 mt-10 flex fy-c', {
          h5: _unref(isMobile)
        }])
      }, [_createElementVNode("div", {
        class: _normalizeClass(['wrapper', _unref(isMobile) ? 'h5' : 'pc'])
      }, [_createVNode(_unref(Swiper), {
        class: "w100 swiper",
        modules: modules,
        navigation: false,
        pagination: true,
        autoplay: {
          delay: 3000,
          speed: 1000,
          loop: true,
          reverseDirection: true,
          disableOnOnsteraction: false
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.videos, (video, i) => {
          return _openBlock(), _createBlock(_unref(SwiperSlide), {
            class: "flex fx-c",
            key: i
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: "slide relative h100 w100 flex fy-c fx-c",
              onClick: $event => openVideo(video)
            }, [_createVNode(_component_el_image, {
              class: "img",
              src: video.img_url,
              fit: "contain"
            }, null, 8, ["src"]), _createVNode(_component_el_icon, {
              class: "icon",
              color: "#fff",
              size: _unref(isMobile) ? 38 : 68
            }, {
              default: _withCtx(() => [_createVNode(_unref(VideoPlay))]),
              _: 1
            }, 8, ["size"])], 8, _hoisted_1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })], 2), _createVNode(_component_PopCover, {
        modalShow: _unref(data).modalShow,
        video: _unref(data).video,
        onClose: close
      }, null, 8, ["modalShow", "video"])], 2);
    };
  }
};