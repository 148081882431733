export default {
  banner: [
    {
      id: 1001,
      img_url: {
        pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/banner-01.png',
        h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/banner-01.png'
      },
      link: 'http://www.airlychair.com/support'
    }
  ],
  ads: [
    {
      id: 1001,
      img_url:
        'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/index-award.png',
      link: 'javascript: void(0)'
    },
    {
      id: 1002,
      img_url:
        'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/index-product.png',
      link: 'http://www.airlychair.com/support'
    }
  ],
  slogan: {
    id: 1001,
    link: 'http://www.airlychair.com/about',
    img_url: {
      h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/h5-index-story.png',
      pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/pc-index-story.png'
    }
  },
  videos: [
    {
      link: 'https://nitetronic.oss-us-west-1.aliyuncs.com/en_bk_website/video/index-chair-01.mp4',
      img_url:
        'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/video/index-chair-01.jpg'
    }
  ],
  innovation: [
    {
      link: 'http://www.airlychair.com/support',
      img_url: {
        h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/h5-tech-innovation-01.png',
        pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/pc-tech-innovation-01.png'
      }
    }
  ],
  footer: {
    pc: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/footer-pc.png',
    h5: 'https://nitetronic.oss-us-west-1.aliyuncs.com/jp/index/footer-h5.png'
  }
}
